

.editor-container {
    margin: 30px auto;
    max-width: 100%;
    overflow-x: scroll;
}

    .editor-container table thead {
        background-color: #5D7B9D;
        color: #FFFFFF;
        word-break:keep-all;
        overflow-wrap:break-word;
    }

    .editor-container .insertform {
        background-color: #5D7B9D;
        color: #FFFFFF;
    }
.narrow {
    max-width: 80px;
}

.form-select-office {
    width: 90px !important;
}

.w-70 {
    width: 70px;
}

.wd-50 {
    width: 50px !important;
}

.wd-185 {
    width: 185px !important;
}