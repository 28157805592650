.timeWarningTable {
    border: 2px solid white !important;
    font-weight: bold;
}

.filter-container label {
    font-weight: bold;
}

.wd-150 {
    width: 150px !important;
}

.wd-100 {
    width: 100px !important;
}

.mw-135 {
    width: 135px !important;
}

.shipment-container {
    margin: 30px auto;
    max-width: 100%;
    overflow-x: scroll;
}

    .shipment-container table thead {
        background-color: #5d7b9d;
        color: #ffffff;
    }

    .shipment-container .insertform {
        background-color: #5d7b9d;
        color: #ffffff;
    }

.shipment-error {
    text-align: center;
    color: #ff0000;
    font-weight: bold;
}

.wd-210{
    width: 200px !important;
}

.wd-300{
    width: 300px !important;
}

.bg-thead {
    background-color: #5d7b9d;
    color: #ffffff;
}

.cardReaderArrow {
    color: #5d7b9d;
    font-size: 100px;
    margin: 1em 0;
}