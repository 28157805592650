.constructionModelSelectContainer {
    margin: 0 auto;
    display: inline-block;
    flex-direction: row;
}

    .constructionModelSelectContainer select {
        display: inline;
        width: 80% !important;
        margin: 0 auto;
    }

    .constructionModelSelectContainer label {
        font-weight: bold;
        margin-right: 5px;
    }

.fileName {
    color: white;
}