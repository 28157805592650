.three-3d-editor-container {
  position: relative;
  width: 100%;
  height: 100%
}

.three-3d-editor-panel {
  position: absolute;
  right: 0;
  left: auto;
  top: 50px;
  bottom: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 220px;
}

.three-3d-editor-panel .control {
  cursor: pointer;
  padding: 5px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.three-3d-editor-panel .control div {
  padding: 6px 10px;
}

.rotate-actions {
  width: 80px;
  height: 80px;
  position: relative;
}

.rotate-actions img {
  max-width: 100%;
  border-radius: 100%;
  height: auto;
}

.rotate-actions .action {
  position: absolute;
  width: 40px;
  height: 40px;
  opacity: 0;
}

.rotate-actions .left {
  left: -10px;
  top: 50%;
  margin-top: -20px;
  transform: rotate(45deg);
}

.rotate-actions .right {
  right: -10px;
  top: 50%;
  margin-top: -20px;
  transform: rotate(45deg);
}

.rotate-actions .up {
  top: -10px;
  left: 50%;
  margin-left: -20px;
  transform: rotate(45deg);
}

.rotate-actions .down {
  bottom: -10px;
  left: 50%;
  margin-left: -20px;
  transform: rotate(45deg);
}

.zoom-actions .action {
  background: #fdd867;
  width: 40px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #5d7b9d;
}
.zoom-actions .in{
  border-radius: 30px 30px 0 0;
}

.zoom-actions .out{
  border-radius: 0 0 30px 30px;
}

.default-action{
  background: #fdd867;
  padding: 5px;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #5d7b9d;
}

.main-3d-editor .action {
  width: 20px;
  height: 25px;
}

.main-3d-editor .default-action {
  min-width: 48px;
  font-size: 11px;
  padding: 5px 0;
  border-radius: 35%;
}

.main-3d-editor .rotate-actions {
  width: 40px;
  height: 40px;
}

.main-3d-editor .rotate-actions .up, .main-3d-editor .rotate-actions .down  {
  margin-left: -10px;
  transform: rotate(0);
}

.main-3d-editor .rotate-actions .right, .main-3d-editor .rotate-actions .left {
  top: 69%;
  transform: rotate(90deg);
}

.three-2d-editor-panel {
  height: 100%;
  top: 76px;
  z-index: 100;
}

.three-2d-editor-panel .zoom-actions .action {
  width: 45px;
  height: 45px;
}

.three-2d-editor-panel .rotate-actions .up {
  top: -6px;
}

.three-2d-editor-panel .rotate-actions .down {
  bottom: 6px;
  margin-left: -25px;
}

.three-2d-editor-panel .rotate-actions .right {
  right: -5px;
  top: 38%;
  margin-top: -20px;
}

.three-2d-editor-panel .rotate-actions .left {
  left: -10px;
  top: 39%;
  margin-top: -20px;
}


@media screen
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 1) {
    .three-2d-editor-panel {
      right: 0px;
      padding: 0;
    }

    .three-2d-editor-panel .control div {
      padding: 2px 3px;
    }

    .three-2d-editor-panel .default-action {
      width: 80%;
    }

    .three-2d-editor-panel .zoom-actions .action {
      width: 29px;
      height: 32px; 
    }
}
