
.setting-container2 {
    margin: 30px auto;
    max-width: 60%;
}

.w-70 {
    width: 70px;
}

.wd-185 {
    width: 185px !important;
}
