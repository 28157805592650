.bm-item {
    font-size: 1em !important;
}

    .bm-item svg {
        float: right;
    }

.bm-cross-button {
    right: 20px !important;
}

.header-three-view .form-select-sm {
    padding-top: 0px;
    padding-bottom: 0px;
}

.text-cursor {
    cursor: pointer;
}

.w-80 {
    width: 80px !important;
}
