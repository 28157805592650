.timeWarningTable {
    border: 2px solid white !important;
    font-weight: bold;
}

.filter-container label {
    font-weight: bold;
}


.editor-container {
    margin: 0 auto;
    max-width: 100%;
    height: 70vh;
}

.locationselectForm {
    margin-left: 5px;
}

.shipment-error {
    text-align: center;
    color: #ff0000;
    font-weight: bold;
}

.wd-210 {
    width: 200px !important;
}

.wd-300 {
    width: 300px !important;
}

.bg-thead {
    background-color: #5d7b9d;
    color: #ffffff;
}


.locationSelector-container {
    /* padding-left: 10px !important;
    margin-bottom: 10px; */
}

.locationSelector-container .col-4 {
    padding-right: 10px;
}

    .locationSelector-container table:first-child tr, .locationSelector-container table:first-child td {
        border: none !important;
    }
    .locationSelector-container select {
        width: 100% !important;
    }
    .locationSelector-container label {
        font-size: 15px !important;
    }


.lift-container {
    max-width: 100%;
    height: 65vh;
    overflow-y: auto;
}
