.bg-moving {
    background-color: #d69c4b !important;
}

.sortedClicked {
    font-weight: bold;
    background-color: #9b8888;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-warning-item {
    background-color: #f766ff !important;
}

.bg-danger-item {
    background-color: #F50505 !important;
}
