.login-background {
    background-color: #ffffff;
    height: 100vh;
    width: 100vw;
    position: absolute;
    display: flex;
    flex-direction: column;
}


.header-login {
    /*
    background-image: url('../../app/assets/img/head.png');
    */
    background-repeat: no-repeat;
    text-align: center;
    font-weight: bold;
    color: #000000;
    height: 24px;
    width: 100%;
    background-size: cover;
    background-color: #C5C5C5;
    font-size:18px;
}

.login-container {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    width: 500px;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh - 48px);
    margin: 0 auto;
}

    .login-container img {
        /*
        width: 150px;
        margin-bottom: 10px;
        */
        width: 450px;
        margin-bottom: 100px;

    }

.login-contruction-name,
.login-system-name {
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    padding: 7px;
    width: 100%;
    color: white;
}

.login-form {
    border-radius: 10px;
    border: 1px solid #8d8585;
    padding: 20px 10px;
    width: 100%;
    background-color: #5d7b9d;
}

    .login-form .login-label {
        color: white;
        width: 120px;
        font-weight: bold;
    }

    .login-form .login-row {
        display: flex;
        margin: 15px;
        width: 92%;
        justify-content: center;
        align-items: center;
    }

    .login-form input.login-input {
        padding: 8px;
        width: 100%;
    }

    .login-form div.login-input {
        width: calc(100% - 120px);
    }

    .login-form .login-error {
        color: red;
        font-size: 15px;
        padding: 7px;
        padding-left: 10px;
        background-color: white;
        font-weight: bold;
        margin-left: 120px;
        width: calc(100% - 120px);
    }

    .login-form .login-btn {
        font-size: 15px;
        padding: 7px 25px;
        background-color: #0d6efd;
        font-weight: bold;
        margin-left: 135px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        color: #ffffff;
        border-color: #c4c4c4;
    }

.checkbox-remember {
    width: 18px;
    height: 18px;
    margin-top: 3px;
}

@media screen
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 1) {
    .login-container {
        width: auto;
    }

    .login-form .login-label {
        width: 60px;
    }

    .login-form div.login-input {
        width: calc(100% - 60px);
    }

    .login-form {
        width: 90vw;
    }

    .login-background .image-selector {
        bottom: 4%;
        right: 10%;
        width: 120px;
        height: 60px;
    }
}
