html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

main {
    min-height: calc(100vh - 94px);
}

.navbar {
    padding: 0 8px;
}

.ml-1 {
    margin-left: 1px;
}

.ml-2 {
    margin-left: 2px;
}

.ml-3 {
    margin-left: 3px;
}

.ml-5 {
    margin-left: 5px !important;
}

.mr-1 {
    margin-right: 1px;
}

.mr-2 {
    margin-right: 2px;
}

.mr-3 {
    margin-right: 3px;
}

.mr-5 {
    margin-right: 5px;
}


.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.view-3d-block {
    background-color: #696b67;
    border-left: 1px solid black;
}

#chart {
    height: 34vh;
}

.navbar-brand img {
    height: 37px;
}

.view-3d-content {
    height: 39vh;
}

.view-3d-full {
    height: 84vh;
}

.bg-alert {
    background-color: #aa60b1;
}

.bg-grey {
    background-color: rgb(240, 240, 240);
    color: black;
    border: rgb(240, 240, 240);
}

header {
    padding: 0;
}

    header .float-right {
        display: flex;
        justify-content: flex-end;
    }

.footer {
    background-image: url("assets/img/foot.png");
    background-repeat: no-repeat;
    text-align: center;
    font-weight: bold;
    color: #5d7b9d;
    height: 25px;
    width: 100%;
    background-size: cover;
}

.goHomeButtonContainer {
    text-align: center;
}

    .goHomeButtonContainer input {
        margin: 5px 0;
    }
/*setting*/
.settingItemPageTitle {
    background-color: #5d7b9d;
    color: #ffffff;
    text-align: center;
    line-height: 3em;
}

.setting-container {
    margin: 30px auto;
    max-width: 85%;
}


    .setting-container table thead {
        background-color: #5d7b9d;
        color: #ffffff;
    }

    .setting-container .insertform {
        background-color: #5d7b9d;
        color: #ffffff;
    }

/* .setting-container select {
        width: 300px !important;
    }*/

.button-set-default {
    margin-right: 40px;
}

.button-save-display {
    margin-right: 120px;
}

.setting-error {
    text-align: center;
    color: #ff0000;
    font-weight: bold;
}
/*endsetting*/

/*spinner*/
.spinner {
    position: absolute;
    background: rgb(0 0 0 / 42%);
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    z-index: 9999;
    bottom: 0;
    width: 100%;
}

    .spinner .outer {
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

    .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #45200a;
        border-color: #45200a transparent #45200a transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/* end spinner */

.filter-container {
    margin: 5px 10%;
}

.content-block {
    border: 1px solid #222321;
}

.bg-header-block {
    background-color: #fbc002;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}

.title-header-block {
    width: 90%;
}

.button-setting {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 10%;
    padding-right: 10px;
}

.table-mesh thead {
    background-color: #fce4d6;
}

.table-mesh-content {
    overflow: auto;
    height: 33vh;
}

    .table-mesh-content thead th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fce4d6;
    }

    .table-mesh-content,
    .table-mesh-content td,
    .table-shipping-information thead td {
        box-shadow: inset 1px -1px #000;
    }

        .table-mesh-content th,
        .table-shipping-information th {
            box-shadow: inset 1px 1px #000, 0 1px #000;
        }

.table-mesh {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 0;
}
.table-shipmentlist-bordered td {
    border: 1px solid black !important;
}
.bg-during-casting {
    background-color: #fde699;
}

.bg-end-casting {
    background-color: #c6e0b4;
}
 
.bg-warning-time-exceeded {
    background-color: #f766ff;
}

.bg-time-limit-exceeded {
    background-color: #f84f51;
}


.text-bold {
    font-weight: bold;
}

.bm-menu {
    background: #373a47;
    padding: 2em 1em 0;
    font-size: 1em;
    color: #fffce1;
}

.bm-cross {
    background-color: #bdc3c7 !important;
}

.bm-menu .nav {
    flex-direction: column;
}

.bm-menu .nav-item {
    float: none;
}

.bm-menu .bm-item {
    font-size: 1.2rem;
    color: #fffce1;
    text-align: left;
    padding: 8px 10px;
}

.bm-menu .nav-child {
    padding-left: 30px;
}

    .bm-menu .nav-child .nav-item .bm-item {
        padding: 4px 6px;
        font-size: 1.1rem;
    }

.bm-burger-button {
    display: none;
}

.bm-menu .nav-item.active .bm-item {
    color: #c94e50;
}

.btn-humbugger-menu {
    z-index: 1000;
}


.wd-150 {
    width: 150px !important;
}

.wd-100 {
    width: 100px !important;
}

.mw-135 {
    width: 135px !important;
}


.chartFilter {
    max-height: 30px;
    line-height: inherit;
    font-size: 12px;
    width: 100% !important;
}

.meshSettingControl {
    font-weight: bold;
    cursor: pointer;
    max-width: 5% !important;
}

    .meshSettingControl svg:nth-child(2) {
        margin-top: -10px;
    }

    .meshSettingControl p:nth-child(1) {
        margin-bottom: 0;
    }

.table-40 {
    max-width: 40% !important;
    margin: 0 auto;
}

.settingControlContainer p {
    margin-bottom: 0px !important;
    cursor: pointer;
}

.scrollableContainer {
    position: relative;
    height: 400px;
    overflow: auto;
}

.scrollableContainer table {
    position: relative;
    border-collapse: collapse;
}

.scrollableContainer table thead {
    position: sticky;
    top:0;
}

.scrollableContainer table thead th {
    position: sticky;
    top: 0;
}

.three-3d-list-mesh {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: flex-end;
}

.three-2d-list-mesh {
    position: absolute;
    top: 10px;
    left: 20px;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.list-mesh {
    /* height: 75vh; */
    overflow-y: auto;
    white-space: nowrap;
    padding: 2px 0px;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    height: calc(100vh / 2.5);
}

.three-3d-list-mesh-home {
    bottom: 2px;
}

.list-mesh-home {
    height: calc(100vh / 3.1) !important;
}

.three-2d-list-mesh .list-mesh  {
    height: calc(100vh / 3.5);
}

    .list-mesh div {
        border: 1px solid #4372C7;
        border-bottom: unset;
        padding: 3px 8px;
        cursor: pointer;
        background-color: #FFF2CC;
    }

        .list-mesh div:last-child {
            border-bottom: 1px solid #4372C7;
        }

        .list-mesh div.active-mesh {
            background-color: #FFD966;
        }

.three-3d-editor-container-history {
    height: 88%;
}

    .three-3d-editor-container-history .three-3d-list-mesh {
        height: 80%;
    }

        .three-3d-editor-container-history .three-3d-list-mesh .list-mesh {
            overflow: auto;
        }

.progress-play {
    background-color: #afabab;
}

.progress {
    margin-top: 5px;
    height: 8px;
    background-color: #d8dee8;
    border-radius: 0;
}

.control-play {
    width: 100%;
    flex: 1;
    flex-direction: row;
    padding: 5px 0px 10px 0px;
    background-color: #afabab;
    position: relative;
}

.time-play-history-show {
    padding-left: 30px;
    font-weight: 500;
    width: 185px;
}

.list-button-click {
    width: 45%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-left: 20%;
}

/* .list-button-click-extend {
    width: 51%;
} */

.button-to-end {
    text-align: center;
    border: 2px solid #8ea9dc;
    border-radius: 15px;
    padding: 2px 15px 2px 18px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-left-width: 0;
    position: absolute;
    right: -50px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 45px;
    display: flex;
    align-items: center;
}

.button-stop-all {
    text-align: center;
    border: 2px solid #8ea9dc;
    border-radius: 15px;
    padding: 2px 38px 2px 15px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-right-width: 0;
    position: absolute;
    left: -58px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 45px;
    display: flex;
    align-items: center;
}

.list-button-click div i {
    font-size: 25px;
}

    .list-button-click div i.fa-caret-left, .list-button-click div i.fa-caret-right {
        font-size: 35px;
    }

.list-button-show {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}

    .list-button-show .btn {
        border-radius: 18px;
        width: 85px;
        font-weight: bold;
    }

    .list-button-show .btn-show-type-history {
        color: #769753;
        background-color: #fff2cc;
    }

.list-button-play {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 140px;
    border: 2px solid #8ea9dc;
    border-radius: 15px;
    padding: 2px 10px;
    position: relative;
    align-items: center;
}

.step-play {
    /* position: absolute;
    bottom: 13px;
    right: 0px;
    left: 0;
    margin: 0 auto;
    text-align: center; */
    padding-left: 64px;
    padding-top: 4px;
}

    .step-play .button-step {
        cursor: pointer;
    }


.pl-30 {
    padding-left: 30px !important;
}

.slumplist {
    max-height: 300px;
    overflow-y: scroll;
}

.active-button-play i {
    color: #007dff;
}

.tooltip-inner {
    max-width: 300px !important;
}


.main-dropdown-menu {
    height: 34px;
    width: 100%;
    /* background: white; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
}

.menu-main a, .main-dropdown-menu a {
    color: #0068B6 !important;
    text-decoration: none;
  }
  

.main-dropdown-menu button {
    border: 1px solid #EEEEEE;
    background: none;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    min-width: 90px;
}

.main-dropdown-menu button:hover {
    color: rgb(136, 232, 119);
    background-color: rgb(223 223 223);
}

.menu-main li {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    border: 1px solid #EEEEEE;
    padding-left: 6px;
}

.menu-main li:hover {
    background-color: rgb(223 223 223);
}

.menu-main ul {
    position: absolute;
    background: white;
    margin-top: 0px;
    min-width: 160px;
    min-height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    list-style: none;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transform: translate(0px);
    transition: all 0.4s ease;
    padding-left: 0;
    z-index: 100;
}

.menu-main button:hover + ul, .menu-main ul:hover {
    opacity: 1;
    pointer-events: all;
    transform: translate(0px);
}

.switch-show-mode-home {
    position: absolute;
    top: 10px;
    z-index: 999;
    left: 10px;
}

.active-location-select {
    background-color: #daa520;
}

.h-100 {
    height: 100%;
}

.h-50 {
    height: 50%;
}

.div-scroll-y {
    overflow-y: scroll;
    height: 130px;
}

