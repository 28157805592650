.settingContainer {
    background-color: #5D7B9D;
    border-radius: 20px;
    max-width: 50%;
    margin: 0 auto;
}
    .settingContainer table {
        color: white;
    }
    .settingContainer table a {
        cursor: pointer;
    }

.settingPageTitle {
    color: white;
    font-size: large;
    margin-left: 20px;
}

.settingContainer table td {
    border: 1px solid white !important;
}

.edit-active {
    background-color: #cafbc8;
}
