.header-construction {
  height: 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.header-construction .menu-construction-content {
  z-index: 99999;
  width: 22vw;
}

.title-header-construction {
  flex: 1;
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
}

.toggler-construction{
  /* ALWAYS KEEPING THE TOGGLER OR THE CHECKBOX ON TOP OF EVERYTHING :  */
  z-index:2;
  height: 35px;
  width: 50px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
}

.hamburger-construction {
  position: absolute;
  top: 0;
  right: 0;
  height: 35px;
  width: 40px;
  padding: 0.6rem;

/* FOR DISPLAYING EVERY ELEMENT IN THE CENTER : */

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

/* CREATING THE MIDDLE LINE OF THE HAMBURGER : */

.hamburger-construction > div{
  position: relative;
  top: 0;
  right: 0;
  background: #000000;
  height: 2px;
  width: 60%;
  transition: all  0.4s ease;
}

/* CREATING THE TOP AND BOTTOM LINES : 
TOP AT -10PX ABOVE THE MIDDLE ONE AND BOTTOM ONE IS 10PX BELOW THE MIDDLE: */

.hamburger-construction > div::before,
.hamburger-construction > div::after{
    content: '';
    position: absolute;
    top: -10px;
    background: #000000;
    width: 100%;
    height: 2px;
    transition: all  0.4s ease;
}

.hamburger-construction > div::after{
    top: 10px;
}

/* IF THE TOGGLER IS IN ITS CHECKED STATE, THEN SETTING THE BACKGROUND OF THE MIDDLE LAYER TO COMPLETE BLACK AND OPAQUE :  */

.toggler-construction:checked + .hamburger-construction > div{
    background: rgba(0,0,0,0);
}

.toggler-construction:checked + .hamburger-construction > div::before{
    top: 0;
    transform: rotate(45deg);
    background: #ffffff;
}

/* AND ROTATING THE TOP AND BOTTOM LINES :  */

.toggler-construction:checked + .hamburger-construction > div::after{
    top: 0;
    transform: rotate(135deg);
    background: #ffffff;
}


/* MAIN MENU WITH THE WHITE BACKGROUND AND THE TEXT :  */

.menu-construction{
  background: #25537b;
  width: 0%;
  height: 100vh;

/* APPLYING TRANSITION TO THE MENU :  */

  transition: all 0.4s ease;
  display: none;
}

/* IF THE TOGGLER IS CHECKED, THEN INCREASE THE WIDTH OF THE MENU TO 30% , CREATING A SMOOTH EFFECT :  */
.toggler-construction:checked ~ .menu-construction{
  width: 22vw;
}
@media screen
  and (min-device-width: 500px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {
    .toggler-construction:checked ~ .menu-construction{
      width: 60vw;
    }
}

@media screen
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 1) {
    .toggler-construction:checked ~ .menu-construction{
      width: 70vw;
    }
}

/* STYLING THE LIST :  */

.menu-construction >  div > ul{
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 70px;
  padding-left: 10px;

/* HIDDEN INITIALLY  :  */

  visibility: hidden;
}

.menu-construction > div > ul > li{
    list-style: none;
    padding: 0.5rem;
}

.menu-construction > div > ul > li > ul >li{
  list-style: none;
  padding: 0.2rem;
}

.menu-construction > div > ul > li{
  color: #ffffff !important;
  text-decoration: none;
  font-size: 14px;
}

/* WHEN THE TOGGLER IS CHECKED, CHANGE THE VISIBILITY TO VISIBLE :  */
.toggler-construction:checked ~ .menu-construction {
  display: block;
}

.toggler-construction:checked ~ .menu-construction > div > ul, .toggler-construction:checked ~ .menu-construction > div > ul > li > ul{
  transition: visibility 0.4s ease;
  transition-delay: 0.1s;
  visibility: visible;
}

.toggler-construction:checked ~ .hamburger-construction {
  background-color: transparent;
}

.toggler-construction:checked ~ .menu-construction > div > ul > li > a:hover{
  color: orange;
}

.toggler-construction:checked ~ .menu-construction > div > ul > li > ul > li > a:hover{
  color: orange;
}

.setting-icon {
  text-align: right;
  font-size: 22px;
  padding-right: 10px;
}

.hiddenBlockSuccess-content {
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 13px;
  font-weight: bold;
  background-color: #696b67;
}

.list-select-construction {
  background-color: #696b67;
}

.button-setting-construction {
  position: absolute;
  right: 5px;
  font-size: 18px;
  z-index: 5;
}

.view-3d-construction {
  height: 45vh;
}
.view-3d-construction .three-3d-editor-panel {
  padding: 0;
}

.view-3d-construction .default-action {
  width: 70px;
  padding: 0;
  font-size: 12px;
}

.view-3d-construction .default-action.active {
  background-color: #ffffff;
}


.view-3d-construction .zoom-actions .action {
  width: 36px;
  height: 36px;
}

.view-3d-construction .three-3d-list-mesh {
  left: 5px;
}

.three-3d-list-mesh .lish-mesh div {
  text-align: center;
}

.view-3d-construction .rotate-actions {
  width: 40px;
  height: 40px;
}

.view-3d-construction .rotate-actions .up {
  top: -5px;
  margin-left: -10px;
}
.view-3d-construction .rotate-actions .down {
  bottom: -5px;
  margin-left: -10px;
}
.view-3d-construction .rotate-actions .right {
  right: -5px;
  margin-top: -10px;
}
.view-3d-construction .rotate-actions .left {
  left: -5px;
  margin-top: -10px;
}
.content-mesh-construction {
  position: relative;
}
.content-mesh-construction .table-mesh-construction {
  /* text-align: left; */
  position: relative;
  border-collapse: collapse;
}
.content-mesh-construction {
  overflow: auto;
  height: 40vh;
}
.content-mesh-construction .table-mesh-construction thead {
  position: sticky;
  top:0;
}
.content-mesh-construction .table-mesh-construction thead tr {
  background: #fce4d6;
}
.content-mesh-construction .table-mesh-construction thead th {
  background: #fce4d6;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}

.config-table {
  position: relative;
  width: 65px;
}

.config-table i {
  position: absolute;
  right: 3px;
  top: 7px;
}

.font-14 {
  font-size: 14px !important;
}

.view-3d-construction-full, .view-3d-construction-full canvas {
  width: 100vw;
  height: 100vh;
}

.close-button-edit-mode {
  position: absolute;
  top: 4px;
  left: 8px;
  width: 20px;
  z-index: 100;
}

.close-button-edit-mode i {
  color: white;
  width: 20px;
  height: 20px;
}

.btn-mesh-action {
  width: 140px;
}

.view-3d-block-construction {
  background-color: #696b67;
}

.three-3d-editor-panel-construction {
  right: 12px;
}

.three-3d-editor-panel-construction .rotate-actions {
  width: 120px;
  height: 120px;
}

.view-3d-construction-all, .view-3d-construction-all canvas {
  height: 100vh;
}

.list-view-info-location {
  position: relative;
}

.icon-change-mode-view {
  position: absolute;
  right: 18px;
  top: 2px;
  z-index: 99;
}

.control-full-screen i {
  color: white;
  font-size: 35px;
}

.view-2d-construction .view-3d-block-construction {
  height: 35vh;
}

.view-2d-construction  .three-3d-list-mesh {
  bottom: -5px;
}

#instructions {
  min-height: 30px;
}

.disabled {
  color: #a7a6a6;
}

a {
  cursor: pointer;
}

.content-slim {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 9999;
}

.content-slim-2d {
  top: 30px;
}

.radio-switcher {
  height: 1.5em !important;
  width: 2.5em !important;
}

.content-slim label {
  padding-top: 5px;
  padding-left: 5px;
}

@keyframes blink { 50% { border-color: #e2d5d5 ; }  }

.alert-slim-mode {
  border: 10px #ff0000 solid;
      
  animation: blink 1s;
  animation-iteration-count: infinite;
}

.construction-alert {
  top: 2px;
  right: 2px;
}

.alert-message-slim {
    top: 15px;
    left: 0px;
    right: 0px;
    /*text-align: center;*/
    text-align: right;
    margin-right:100px;
    z-index: 999;
    color:#FF0000;
}

.alert-message-slim-2d {
  top: -75px;
}

.alert-message-slim-home {
  right: 10px;
  left: unset;
}

.w-120 {
  width: 120px;
}

.content-form-check {
  width: 150px;
  z-index: 9999;
}

.switch-show-mode {
  width: calc(100% - 150px);
}

.text-show-mode {
  font-size: 16px;
}

.btn-show-mode {
  width: 120px;
}

@media screen
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 1) {
    .text-show-mode {
      font-size: 10px;
    }

    .btn-show-mode {
      width: 80px;
    }

    .content-form-check {
      width: 120px;
    }

    .switch-show-mode {
      width: calc(100% - 120px);
    }
}
