a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.navbar {
    background-color: #ffffff;
}

.navContainer {
    
}

    .navContainer ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

.nav-item {
    float: left;
    font-weight: bold;
}

    .nav-item a {
        display: block;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        color: black;
    }

        .nav-item a:hover {
            color: white;
            background-color: #5D7B9D;
            cursor: pointer;
        }
.display-important {
    display: block !important;
}