.loginSelector-container {
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    width: 500px;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh - 30px);
    margin: 0 auto;
}

.loginSelector-container ul {
    list-style: none;
}

.loginSelector-container ul li {
    margin-top: 15px;
}

.item-selector {
    height: 38vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px;
    border: 1px solid #568bfb;
    border-radius: 30px; 
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.title-selector {
    cursor: pointer;
}

.image-selector {
    position: absolute;
    bottom: 12%;
    right: 8%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 150px;
    height: 100px;
}

.image-selector-office {
    background-image: url('../../app/assets/office.png');
}

.image-construction {
    background-image: url('../../app/assets/construction.png');
}

.image-concrete-factory {
    background-image: url('../../app/assets/concrete-factory.png');
}

.image-concrete-car {
    background-image: url('../../app/assets/concrete-car.png');
}

.w-55 {
    width: 55%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.select-child {
    background-image: linear-gradient(#000, #000), linear-gradient(#000, #000), linear-gradient(#000, #000), linear-gradient(#000, #000);
    background-repeat: no-repeat;
    background-size: 9px 2px;
    background-position: top left, top right, bottom left, bottom right;
    border-radius: 14px;
    border: solid #000;
    border-width: 0 1px;
    padding: 0 6px;
    margin-left: 14%;
}

.image-card-ic {
    width: 90px;
}
.footer {
    background-image: url('../../app/assets/img/head.png');
    background-repeat: no-repeat;
    text-align: center;
}

@media screen
    and (min-device-width: 500px)
    and (max-device-width: 1080px)
    and (-webkit-min-device-pixel-ratio: 1) {
    .loginSelector-background {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
    }

    .item-selector {
        margin: 25px;
    }

    .pump-container .image-concrete-car {
        width: 80px;
        height: 40px;
    }

    .pump-container .w-55 {
        width: 100%;
    }
}

@media screen
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 1) {
    .loginSelector-background {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
    }

    .item-selector {
        height: 20vh;
        margin: 5px 20px 10px 20px;
    }

    .image-card-ic {
        width: 40px;
    }

    .image-selector {
        bottom: 4%;
        right: 10%;
        width: 50px;
        height: 33px;
    }

    .pump-container a, .pump-container div {
        font-size: 14px !important;
    }
}